const options = {
  tooltips: {
    mode: 'label'
  },
  scales: {
    yAxes: [{
      stacked: true,
      gridLines: {
        display: false
      },
      ticks: {
        fontFamily: "Poppins",
        fontColor: "#878787"
      }
    }],
    xAxes: [{
      stacked: true,
      display: false
    }],
  },
  elements: {
    point: {
      hitRadius: 40
    }
  },
  animation: {
    duration: 1000
  },
  responsive: true,
  legend: {
    display: false,
  },
  tooltip: {
    backgroundColor: 'rgba(33,33,33,1)',
    cornerRadius: 0,
    footerFontFamily: "'Poppins'"
  }
}

const wrapRemoteData = function (chartCanvas, chartData) {
  if (chartData.datasets) {
    return chartData.datasets
  } else {
    const {color, hoverColor} = chartCanvas.dataset

    return [
      {
        backgroundColor: color,
        borderColor: color,
        hoverBackgroundColor: hoverColor,
        hoverBorderColor: hoverColor,
        data: chartData.data
      }
    ];
  }
}

module.exports = function (chartCanvas) {
  if (chartCanvas) {
    const ctx = chartCanvas.getContext("2d");
    const data = {
      labels: [],
      datasets: []
    };

    const chart = new Chart(ctx, {
      type: 'bar',
      data: data,

      options: options
    });


    fetch(chartCanvas.dataset.endpoint)
      .then(response => response.json())
      .then(jsonResponse => {
        const chartData = jsonResponse.chart_data

        chart.data.labels = chartData.labels;
        chart.data.datasets = wrapRemoteData(chartCanvas, chartData)

        chart.update();
      });
  }
}
