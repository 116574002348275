const setStylingOptions = function (editor, $element) {
  const theme = $element.data('theme') || 'dawn'
  editor.setTheme(`ace/theme/${theme}`);
  const mode = $element.data('mode') || 'python'
  editor.session.setMode(`ace/mode/${mode}`);
}

module.exports = function (brace) {
  let editorsOnPage = {
    readonly: {},
    editable: {}
  }

  $('.ace-editor-readonly').each(function () {
    const editor = brace.edit(this)
    const $this = $(this)

    setStylingOptions(editor, $this)

    editor.setReadOnly(true);

    const id = $this.attr('id')
    if (id) {
      editorsOnPage.readonly[id] = editor
    }
  })

  $('.ace-editor').each(function () {
    const editor = brace.edit(this)
    const $this = $(this)

    setStylingOptions(editor, $this)

    const hiddenFieldSelectors = $this.data('hidden_field')
    // it will take val from first selector and apply it to all separated by ', '
    const $hiddenField = $(hiddenFieldSelectors.split(', ')[0]);
    editor.session.setValue($hiddenField.val());

    const $hiddenFields = $(hiddenFieldSelectors)
    editor.session.on('change', function () {
      $hiddenFields.val(editor.session.getValue());
    });

    const id = $this.attr('id')
    if (id) {
      editorsOnPage.editable[id] = editor
    }
  })

  return editorsOnPage
}
