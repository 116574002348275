const addRecordFieldSelector = '.add-record-field'
const removeRecordFieldSelector = '.remove-record-field'
const mappingFieldSelector = '.mapping-field'
const nestedFieldsSelector = '.mapping-nested-fields'
const recordPrefixSelector = '.record-prefix'
const formRowSelector = '.form-row'
const dataPrefixKeys = 'prefix-keys'
const attrDataPrefixKeys = 'data-' + dataPrefixKeys

const arrToData = function (arr) {
  return '["' + arr.join('", "') + '"]'
}

module.exports = function ($body) {
  $body.on('change', '.mapping-field-type-select', function (event) {
    const $currentTarget = $(event.currentTarget);
    const typeSelectVal = $currentTarget.val();

    const $mappingField = $currentTarget.closest(mappingFieldSelector);
    const $formRow = $mappingField.children(formRowSelector)
    const $fromnNameInput = $formRow.find('.mapping-field-from-name-input')

    if ((typeSelectVal === 'RECORD' || typeSelectVal === 'FLATTEN') && $fromnNameInput.val().length !== 0) {
      const prefixkeys = $formRow.children(recordPrefixSelector).data(dataPrefixKeys)
      const joinWith = typeSelectVal === 'RECORD' ? '.' : '_'
      $mappingField.children(nestedFieldsSelector).find(recordPrefixSelector).each(function () {
        const $nestedRecordPrefix = $(this)
        const nestedRecordPrefixData = $nestedRecordPrefix.data(dataPrefixKeys)
        nestedRecordPrefixData[prefixkeys.length + 1] = joinWith
        $nestedRecordPrefix.attr(attrDataPrefixKeys, arrToData(nestedRecordPrefixData))
        $nestedRecordPrefix.html(nestedRecordPrefixData.join(''))
      })
      $formRow.find(addRecordFieldSelector).removeClass('d-none')
    } else {
      $formRow.find(addRecordFieldSelector).addClass('d-none')
      $mappingField.find(nestedFieldsSelector).empty()
    }
  })

  $body.on('keyup', '.mapping-field-from-name-input', function (event) {
    const $currentTarget = $(event.currentTarget);
    const fromName = $currentTarget.val();

    const $mappingField = $currentTarget.closest(mappingFieldSelector);
    const $formRow = $mappingField.children(formRowSelector)
    const typeSelectVal = $formRow.find('.mapping-field-type-select').val()

    if ((typeSelectVal === 'RECORD' || typeSelectVal === 'FLATTEN') && fromName.length !== 0) {
      $formRow.find(addRecordFieldSelector).removeClass('d-none')
    } else {
      $formRow.find(addRecordFieldSelector).addClass('d-none')
      $mappingField.find(nestedFieldsSelector).empty()
    }
  })

  $body.on('keyup', '.mapping-field-to-name-input', function (event) {
    const $currentTarget = $(event.currentTarget);
    const toName = $currentTarget.val();

    const $mappingField = $currentTarget.closest(mappingFieldSelector);
    const $formRow = $mappingField.children(formRowSelector)
    const typeSelectVal = $formRow.find('.mapping-field-type-select').val()

    if ((typeSelectVal === 'RECORD' || typeSelectVal === 'FLATTEN') && toName.length !== 0) {
      const prefixKeys = $formRow.children(recordPrefixSelector).data(dataPrefixKeys)
      const prefixKeysLength = prefixKeys.length

      $mappingField.children(nestedFieldsSelector).find(recordPrefixSelector).each(function () {
        const $nestedRecordPrefix = $(this)
        const newPrefixKeys = $nestedRecordPrefix.data(dataPrefixKeys)
        newPrefixKeys[prefixKeysLength] = toName
        const newPrefix = newPrefixKeys.join('')

        $nestedRecordPrefix.attr(attrDataPrefixKeys, arrToData(newPrefixKeys)).html(newPrefix)
      })
    }
  })

  $body.on('click', removeRecordFieldSelector, function (event) {
    const $currentTarget = $(event.currentTarget);
    const $mappingField = $currentTarget.closest(mappingFieldSelector)

    $mappingField.remove()
  })

  $body.on('click', addRecordFieldSelector, function (event) {
    const $currentTarget = $(event.currentTarget);
    const $mappingField = $currentTarget.closest(mappingFieldSelector)
    // starts at zero
    const nestedIndex = $mappingField.children(nestedFieldsSelector).children(mappingFieldSelector).length
    const $formRow = $mappingField.children(formRowSelector)
    const prevPrefixKeys = $formRow.children(recordPrefixSelector).data(dataPrefixKeys)
    const $prevToNameInput = $formRow.find('.mapping-field-to-name-input')
    const $typeSelect = $formRow.find('.mapping-field-type-select')

    const $newNestedField = $('.sample-record-field').clone()

    $newNestedField.find(removeRecordFieldSelector).removeClass('d-none')

    // trick to get rid of first dot if there was no prefix
    const joinNextWith = $typeSelect.val() === 'RECORD' ? '.' : '_'
    const newPrefixKeys = [...prevPrefixKeys, $prevToNameInput.val(), joinNextWith].filter(function (e) {
      return e
    })

    const newPrefix = newPrefixKeys.join('')
    $newNestedField.find(recordPrefixSelector).attr(attrDataPrefixKeys, arrToData(newPrefixKeys)).html(newPrefix)

    const nestingCount = $prevToNameInput.attr('name').split('fields').length

    const $newNestedFieldFormRow = $newNestedField.find(formRowSelector)
    for (let i = 0; i < nestingCount - 1; i++) {
      $newNestedFieldFormRow.prepend('<div class="ml-20"/>')
    }

    const $newNestedFieldFromNameSpan = $newNestedField.find('span.mapping___SAMPLE___from_name')
    const $newNestedFieldToNameSpan = $newNestedField.find('span.mapping___SAMPLE___to_name')
    const $newNestedFieldTypeSpan = $newNestedField.find('span.mapping___SAMPLE___type')
    const $newNestedFieldModeSpan = $newNestedField.find('span.mapping___SAMPLE___mode')
    const $newNestedFieldStatusSpan = $newNestedField.find('span.mapping___SAMPLE___status')

    const newSpanClassPrefix = 'mapping_destination_field_' + Array(nestingCount).fill('fields').join('_')
    $newNestedFieldFromNameSpan.toggleClass('mapping___SAMPLE___from_name ' + newSpanClassPrefix + '_from_name')
    $newNestedFieldToNameSpan.toggleClass('mapping___SAMPLE___to_name ' + newSpanClassPrefix + '_to_name')
    $newNestedFieldTypeSpan.toggleClass('mapping___SAMPLE___type ' + newSpanClassPrefix + '_type')
    $newNestedFieldModeSpan.toggleClass('mapping___SAMPLE___mode ' + newSpanClassPrefix + '_mode')
    $newNestedFieldStatusSpan.toggleClass('mapping___SAMPLE___status ' + newSpanClassPrefix + '_status')

    const $newInputIdPrefix = $prevToNameInput.attr('id').replace('_to_name', '_fields_' + nestedIndex)
    const $newInputNamePrefix = $prevToNameInput.attr('name').replace('[to_name]', '[fields][' + nestedIndex + ']')

    $newNestedFieldFromNameSpan.find('label').attr('for', $newInputIdPrefix + '_from_name')
    $newNestedFieldFromNameSpan.find('input').attr('id', $newInputIdPrefix + '_from_name')
    $newNestedFieldFromNameSpan.find('input').attr('name', $newInputNamePrefix + '[from_name]')
    $newNestedFieldToNameSpan.find('label').attr('for', $newInputIdPrefix + '_to_name')
    $newNestedFieldToNameSpan.find('input').attr('id', $newInputIdPrefix + '_to_name')
    $newNestedFieldToNameSpan.find('input').attr('name', $newInputNamePrefix + '[to_name]')
    $newNestedFieldTypeSpan.find('label').attr('for', $newInputIdPrefix + '_type')
    $newNestedFieldTypeSpan.find('select').attr('id', $newInputIdPrefix + '_type')
    $newNestedFieldTypeSpan.find('select').attr('name', $newInputNamePrefix + '[type]')
    $newNestedFieldModeSpan.find('label').attr('for', $newInputIdPrefix + '_mode')
    $newNestedFieldModeSpan.find('select').attr('id', $newInputIdPrefix + '_mode')
    $newNestedFieldModeSpan.find('select').attr('name', $newInputNamePrefix + '[mode]')
    $newNestedFieldStatusSpan.find('label').attr('for', $newInputIdPrefix + '_status')
    $newNestedFieldStatusSpan.find('select').attr('id', $newInputIdPrefix + '_status')
    $newNestedFieldStatusSpan.find('select').attr('name', $newInputNamePrefix + '[status]')

    $mappingField.children(nestedFieldsSelector).append($newNestedField.html())
  })
}
