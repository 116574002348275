export function setAsyncTimeoutWithTurbolinks(intervalFunction, args, milliseconds) {
  let schedule = true;

  let timeout = setTimeout(
    function run(){
      intervalFunction(...args).then(() => {
        console.log('asyncTimeoutWithTurbolinksTick')

        if (schedule) {
          timeout = setTimeout(run, milliseconds)
        }
      })
    },
    0
  )

  $(document).on('turbolinks:before-cache turbolinks:before-render', function () {
    schedule = false
    clearTimeout(timeout);
  });
}
