const options = {
  scales: {
    yAxes: [{
      stacked: true,
      display: false

    }],
    xAxes: [{
      stacked: true,
      display: false
    }],
  },
  animation: {
    duration: 200,
    easing: 'easeInCubic'
  },
  responsive: true,
  legend: {
    display: false,
  }
}

const wrapRenderedData = function (plainData) {
  if (plainData.chartDatasets) {
    return plainData.chartDatasets
  } else {
    const {color, hoverColor, chartData: data} = plainData

    return [
      {
        backgroundColor: color,
        borderColor: color,
        hoverBackgroundColor: hoverColor,
        hoverBorderColor: hoverColor,
        minBarLength: 1,
        data: data
      }
    ];
  }
}

module.exports = function (chartCanvas) {
  if (chartCanvas) {
    const ctx = chartCanvas.getContext("2d");

    const plainData = $(chartCanvas).data()

    const data = {
      labels: plainData.chartLabels || [],
      datasets: wrapRenderedData(plainData) || []
    };

    return new Chart(ctx, {
      type: 'bar',
      data: data,

      options: options
    });
  }
}
