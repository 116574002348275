const dig = require('object-dig');

const idToRemoteFieldKeys = {
  'total-input-events-count': ['inputs', 'total_events_count'],
  'period-input-events-count': ['inputs', 'period_events_count'],
  'last-interval-input-events-count': ['inputs', 'last_interval_events_count'],

  'total-output-events-count': ['outputs', 'total_events_count'],
  'period-output-events-count': ['outputs', 'period_events_count'],
  'last-interval-output-events-count': ['outputs', 'last_interval_events_count'],

  'total-failed-events-count': ['failed', 'total_events_count'],
  'period-failed-events-count': ['failed', 'period_events_count'],
  'last-interval-failed-events-count': ['failed', 'last_interval_events_count'],

  'recent-unprocessed-events-count': ['unprocessed_events'],
  'total-processed-events-count': ['total_processed_events'],
  'failures-count': ['failures']
}

function updateChart(responseData, rootKey, chart) {
  const chartLabels = responseData[rootKey].chart.labels
  const chartData = responseData[rootKey].chart.data

  chart.data.labels = chartLabels;
  chart.data.datasets.forEach(dataset => {
    dataset.data = chartData;
  });

  chart.update();
}

export function dashboardUpdateInterval(period) {
  return {
    '1_hour': 2000,
    '1_day': 3500,
    '1_week': 5000,
    '30_days': 10000
  }[period]
}

export function updateDashboard(period, dashboardCharts) {
  return new Promise((resolve, reject) => {
    fetch(
      `?period=${period}`,
      {
        headers: {
          'Accept': 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(responseData => {
        $.each(dashboardCharts, (rootKey, chart) => updateChart(responseData, rootKey, chart))
        $.each(idToRemoteFieldKeys, (id, keys) => {
          const value = dig(responseData, ...keys)
          $(`#${id}`).html(value)
        })
        resolve()
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}
