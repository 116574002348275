// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// FIXME: I'm deeply sorry for this file, USE REACT PLEASE, it was worst decision ever to use stock rails setup

import 'bootstrap'

require('@rails/ujs').start()
require('turbolinks').start()
// require('@rails/activestorage').start()
// require('channels')

require('cocoon')

// TODO: check if needed
// import 'jquery-ui'

// TODO: if u have a TON of time please figure out how to make it via webpack
import '../../../vendor/unique-admin/assets/vendor_components/jquery-slimscroll/jquery.slimscroll.js'
import '../../../vendor/unique-admin/assets/vendor_components/datatables.net/js/jquery.dataTables.min.js'

import '../../../vendor/unique-admin/mini-sidebar/js/template.js'

import toastr from 'toastr'
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    cancelButton: 'btn btn-default mr-15',
    confirmButton: 'btn btn-success'
  },
  buttonsStyling: false
})

$(document).on('click', '[data-sweet-alert-confirm]', event => {
  const $target = $(event.target)
  const data = $target.data()

  console.log(data)

  const sweetAlertConfirm = data.sweetAlertConfirm
  let conf = {
    text: data.text,
    confirmButtonText: data.confirmBtnText || 'Ok',
    reverseButtons: true
  }

  if (sweetAlertConfirm) {
    event.preventDefault()
    conf.title = sweetAlertConfirm
    conf.icon = 'warning'
    conf.showCancelButton = true
  }

  swalWithBootstrapButtons
    .fire(conf)
    .then((result) => {
      if (result.isConfirmed) {
        const href = data.href

        if (href) {
          const method = data.method
          if (method === 'post' || method === 'patch' || method === 'delete') {
            $.ajax({
              type: "POST",
              url: href,
              data: {"_method": method}
            })
          } else {
            Turbolinks.visit(href)
          }
        }
      }
    })
})

toastr.options = {
  closeButton: true,
  positionClass: "toast-bottom-right"
};
window.toastr = toastr

import brace from 'brace'
import 'brace/mode/python'
import 'brace/mode/javascript'
import 'brace/theme/dawn'

const initBarChart = require('./dashboard/bar-chart.js')
const initRemoteBarChart = require('./dashboard/remote-bar-chart.js')
const initMappingForm = require('./dashboard/mapping-form.js')
const initAceEditors = require('./dashboard/ace-editors.js')
const initProcessorEditor = require('./dashboard/processor-editor.js')

import {recursiveMainDashboardUpdate} from './dashboard/main-dashboard.js';
import {recursiveInputOverviewUpdate} from './dashboard/inputs.js';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Code for going back in browser history
let dataTable = null

document.addEventListener('turbolinks:before-cache', function () {
  const flashMessages = document.getElementById('flash-messages')

  if (flashMessages) {
    flashMessages.remove();
  }

  if (dataTable !== null) {
    dataTable.destroy();
    dataTable = null;
  }
});

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()

  $('[data-widget="tree"]').each(function () {
    $.fn.tree.call($(this))
  })

  $('.box').each(function () {
    $.fn.boxWidget.call($(this))
  })

  $.fn.pushMenu.call($('[data-toggle="push-menu"]'))

  let dashboardCharts = {};

  const $mainDashboard = $('#main-dashboard');
  const isMainDashboard = $mainDashboard.length !== 0;
  const $inputOverview = $('#input-overview');
  const isInputOverview = $inputOverview.length !== 0;

  $('canvas.plain-bar-chart').each(function () {
    const chart = initBarChart(this);

    if (isMainDashboard || isInputOverview) {
      const $this = $(this)
      dashboardCharts[$this.data('auto-refresh-root-key')] = chart
    }
  })

  if (isMainDashboard) {
    const period = $mainDashboard.data('period')
    recursiveMainDashboardUpdate(period, dashboardCharts)
  }

  if (isInputOverview) {
    const period = $inputOverview.data('period')
    recursiveInputOverviewUpdate(period, dashboardCharts)
  }

  $('canvas.remote-bar-chart').each(function () {
    initRemoteBarChart(this);
  })

  const $table = $('table.data-table')
  dataTable = $table.DataTable({
    serverSide: true,
    ajax: $table.data('remote-path'),
    searching: false,
    iDisplayLength: $table.data('per-page'),
    iDisplayStart: ($table.data('page') - 1) * $table.data('per-page'),
    order: [[0, 'desc']],
    columnDefs: [
      {
        targets: $table.data('id-col'),
        render: function (data, type, _row, _meta) {
          const path = $table.data('row-href-path-template').replace(/__ID__/, data);

          if (type === 'display') {
            data = '<a href="' + path + '">' + data + '</a>';
          }

          return data;
        }
      },
      {
        targets: $table.data('html-col'),
        render: function (data, type, row, _meta) {
          return data.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        }
      }
    ]
  });

  const $body = $('body')
  $.fn.layout.call($body)

  const editors = initAceEditors(brace)

  if (document.getElementById('new_mapping') || document.getElementsByClassName('edit_mapping').length !== 0) {
    initMappingForm($body)
  }

  if (document.getElementById('processor-editor')) {
    initProcessorEditor($body, {editors: editors})
  }
})
