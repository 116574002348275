module.exports = function ($body, options) {
  $body.on('ajax:success', '#get-sample-form', function (event) {
    [data, status, xhr] = event.detail

    const sample = data.event
    const prettySample = JSON.stringify(sample, null, 2)
    options.editors.editable['test-event-editor'].session.setValue(prettySample)
  })

  $body.on('ajax:error', '#get-sample-form', function (event) {
    [data, status, xhr] = event.detail

    if (xhr.status === 404) {
      window.toastr.error('There are no samples for selected input event')
    }
  })

  $body.on('ajax:success', '#test-event-form', function (event) {
    [data, status, xhr] = event.detail

    const editorSession = options.editors.readonly['tested-event'].session

    if (xhr.status === 200) {
      const content = data.result
      const prettyContent = JSON.stringify(content, null, 2)
      editorSession.setValue(prettyContent)
    } else if (xhr.status === 204) {
      window.toastr.success('Looks like processor code for provided sample has returned None which means that event will be rejected from processing')
      editorSession.setValue('')
    }
  })

  $body.on('ajax:error', '#test-event-form', function (event) {
    [data, status, xhr] = event.detail

    const content = data.reason

    if (xhr.status === 400) {
      const prettyContent = JSON.stringify(content, null, 2)
      options.editors.readonly['tested-event'].session.setValue(prettyContent)
      window.toastr.error('Something is wrong with sample or processor code')
    }

    if (xhr.status === 422) {
      window.toastr.error(content)
    }

    if (xhr.status === 500) {
      window.toastr.error('Something wrong, please try again later or contact support')
    }
  })
}
